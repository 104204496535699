.container {
    width: 1870px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-items: left;
}

.botany-appbar {
    width: 1870px;
}

.headtext {
    text-align: left;
    color: #FFFFFF;
    padding: 20px;
}

.image-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}

.neet-main-img {
    margin: 80px;
    width: 1300px;
    height: 740px;
}

.text-area1 {
    position: absolute;
    left: 210px;
    top: 200px;
}

.text-area2 {
    position: absolute;
    left: 440px;
    top: 600px;
}

.live-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    background-color: #FFFFFF;
    color: #000;
    text-transform: initial;
}

.live-content {
    top: 105px;
    left: 230px;
    width: 500px;
    position: relative;
}

.live-class {
    position: absolute;
    top: 110px;
    left: 255px;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
}

.live-para {
    position: absolute;
    top: 175px;
    padding: 0px;
    right: -43px;
    font-size: 20px;
}

.live-img {
    width: 20px;
    padding: 5px;
}

.upload-img {
    width: 20px;
    padding: 5px;
    top: 100px;
}

.card-section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    cursor: pointer;
}

.card-title {
    text-align: center;
    padding: 10px;
    cursor: pointer;
}

.card-section .main-cards {
    width: 500px;
    height: 325px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card-img {
    width: 100px;
    padding: 10px;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .container {
        width: 400px;
        margin: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        align-items: left;
    }

    .botany-appbar {
        width: 375px;
    }

    .main-img {
        padding: 20%;
        width: 350px;
        height: 740px;
    }

    .live-content {
        top: 150px;
        right: 5px;
        width: 260px;
        position: relative;
    }

    .live-class {
        position: absolute;
        top: 145px;
        padding: 5px;
        font-size: 17px;
        font-weight: bold;
    }

    .text-area1 {
        top: 150px;
        left: 50px;
        width: 260px;
    }

    .text-area2 {
        position: absolute;
        left: 170px;
        top: 600px;
    }

    .card-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 20px;

    }

    .neet-cards {
        padding: 25px;
    }

    .card-title {
        text-align: center;
        padding: 10px;
    }

    .card-section .main-cards {
        width: 400px;
        height: 325px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .card-img {
        width: 100px;
        padding: 10px;
    }
}