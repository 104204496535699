.ql-container.ql-snow {
  border: none !important;
}
.units {
  background-color: var(--text-color);
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.videosPart {
  cursor: pointer;
  width: 50%;
  height: 25%;
}
.reactquilfor {
  font-size: 50px;
}
.ql-editor {
  font-size: 140px;
}
.unitCard {
  color: white;
  font-weight: 800;
  font-size: 26px;
  letter-spacing: 2px;
}
.unitspartname {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.unitsDivs {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100px;
  width: 95%;
}
.videoQuesandQA {
  width: 100%;
  margin-top: -40px;
}
.sssssss {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.unitTypo {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
  opacity: 0.9;
}
.unitquesansw {
  width: 80%;
}
.emptyques {
  display: flex;
  flex-direction: column;
  align-items: center;
 
}
.emptyquesimage {
  width: 70%;

}
.sizeForDuration {
  font-size: 13px;
}
.mediaplayer {
  width: 100%;
  border-radius: 20px;
  border: 1px solid black;
  /* height: 55vh; */
  overflow: hidden;
  background-color: black;
}
.mainVideopartDiv {
  width: 70%;
}

/* <--- Units Subject and Videos ---> */
.unitVideosMainDiv {
  display: flex;
  justify-content: space-between;
  padding: 10px 50px;
  gap: 10px;
  margin-top: -10px;
  /* background-color: green; */
}
.unitsSecSubHeads {
  background-color: #e4effa;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 20px;
}
.bb {
  overflow-x: hidden;
  max-height: 574px;
  width: 100%;
}
.sideVideoPar {
  max-height: 580px;
  overflow-x: hidden;
  width: 30%;
  margin-top: 30px;
}
.scrolling {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.firstUnitsSubHeads {
  width: 10%;
  height: 100px;
  display: flex;
  align-items: center;
}
.unitsSubHeads {
  display: flex;
  margin: 15px 0px;
}
.unitsVideoSide {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.unitNameAndAerrow {
  width: 80%;
}
.noroll {
  width: 13%;
}
.rollno {
  background-color: var(--text-color);
  width: 80px;
  height: 30px;
  padding-top: 5px;
  border-bottom-right-radius: 36%;
  margin: 0px;
  text-align: center;
  color: white;
  font-size: 13px;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
}
.unitheading {
  margin-left: 2px;
  text-align: center;
  font-size: 15px;
  /* background-color: violet; */
}
.aerrowFordIcon {
  display: flex;
  align-self: center;
  justify-self: flex-end;
}
.unitVideos {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  width: 120px;
  cursor: pointer;
  border-radius: 200px;
  padding: 25px;
}
.unitVideoss {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  width: 190px;
  cursor: pointer;
  border-radius: 300px;
  padding: 25px;
}
.circleOutLineVideosIcon {
  color: #287f87;
  font-weight: 800;
  font-size: 18px;
}
.videoMesgQuizDiv {
  display: flex;
  justify-content: center;
}
.description {
  border: 3px solid #bfbfbf;
  border-radius: 10px;
  width: 94%;
}
.bookMarDivs {
  display: flex;
  justify-content: space-between;
}
.marBook {
  margin: 15px;
  cursor: pointer;
}
.marBooks {
  margin: 15px;
  font-size: 20px;
}
.viewlessmore {
  display: flex;
  justify-content: flex-end;
}
.whatsapp {
  width: 80%;
  margin-left: 23vh;
  margin-bottom: 7vh;
}
.viewbtn {
  color: var(--bgcolor);
  cursor: pointer;
  text-decoration: underline;
  margin-left: 5px;
}
.scrollPart {
  display: flex;
  flex-direction: column;
  gap: 30px;
  /* align-items: center; */
}
.playListData {
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reactPlayer {
  width: 740px;
  height: 417px;
}

.unitSubSide {
  width: 100%;
  padding: 10px 40px;
}
.unitsCards {
  margin: 10px;
  width: 98%;
  display: flex;
  justify-content: space-between;
  border: 1px solid #3c4048;
  cursor: pointer;
}
@media (min-width: 1200px) and (max-width: 1700px) {
  .unitheading {
    margin-left: 2px;
    text-align: center;
    font-size: 13px;
  }
  .rollno {
    background-color: var(--text-color);
    width: 70px;
    height: 27px;
  }
  .circleOutLineVideosIcon {
    font-weight: 600;
    font-size: 16px;
  }

  .unitsSecSubHeads {
    width: 100%;
  }
}
@media (min-width: 900px) and (max-width: 1200px) {
  .unitheading {
    margin-left: 2px;
    text-align: center;
    font-size: 11px;
  }
  .rollno {
    background-color: var(--text-color);
    width: 60px;
    height: 25px;
  }
  .unitsSecSubHeads {
    width: 58%;
  }
  .unitsSubHeads {
    width: 69%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .circleOutLineVideosIcon {
    font-weight: 600;
    font-size: 14px;
  }
  .unitVideos {
    width: 110px;
    height: 37px;
  }
}

@media (min-width: 1025px) and (max-width: 1813px) {
  .reactPlayer {
    width: 500px;
    height: 282px;
  }
}

@media (min-width: 500px) and (max-width: 900px) {
  .scrolling {
    width: 98%;
  }
  .secondDivUnitsSubjectsAndVideos {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
  }
  .reactPlayer {
    width: 100%;
  }

  .firstUnitsSubHeads {
    width: 20%;
  }
  .unitVideos {
    width: 97px;
    height: 30px;
    gap: 3px;
  }
  .circleOutLineVideosIcon {
    font-weight: 800;
    font-size: 12px;
  }
  .unitsSubHeads {
    width: 58%;
    display: flex;
    justify-content: center;
    height: 100px;
    align-items: center;
  }
  .unitsSecSubHeads {
    display: flex;
    align-items: center;
    width: 73%;
    justify-content: space-around;
    height: 50px;
    border-radius: 20px;
  }
  .unitVideoss {
    width: 80px;
    height: 20px;
  }
}

.unitQuizes {
  display: flex;
  flex-direction: column;
  height: 280px;
  justify-content: space-evenly;
  margin-top: 100px;
  align-items: center;
}

.QAshowandhide {
  display: flex;
  align-self: flex-start;
  justify-content: flex-end;
  align-items: flex-end;
  color: red;
}
.qacard {
  width: 800px;
}

.content.show {
  height: auto;
  max-height: 9999;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}
.wrapperes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 45px;
  /* background-color: yellow; */
}
.accordion {
  width: 100%;
  padding: 10px 20px;
  margin-top: 15px;
  border-radius: 10px;
  box-sizing: border-box;
  /* border: 1px solid red; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.item {
  margin-bottom: 5px;
  padding: 2px 20px;
  cursor: pointer;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-direction: column; */
  /* background-color: green; */
}
.answer {
  display: flex;
  justify-content: flex-end;
}
.content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}
.showAnswer {
  color: green;
}
.hideAnswer {
  color: red;
}
.easymediumhard {
  text-align: center;
  font: 2em sans-serif;
}

.answercard {
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  cursor: pointer;
}
.quill-editor {
  cursor: pointer;
}
.scorediv {
  border: 2px solid var(--bgcolor);
  width: 300px;
  height: 180px;
}
.scorehead {
  background-color: var(--bgcolor);
  height: 55px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
}
.scorename {
  margin: 0px;
}
.scoreandcupdiv {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-around;
  height: 125px;
}
.cups {
  width: 35px;
}

.smalldiv {
  border: 2px solid var(--bgcolor);
  width: 100px;
  height: 100px;
  margin-top: 20px;
}
.firstdivavt {
  background-color: var(--bgcolor);
  height: 25px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
}
.scorename {
  margin: 0px;
}
.smalldivavtr {
  display: flex;

  align-items: center;

  justify-content: center;
  height: 75px;
}
.fourdivcontain {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 300px;
}
.buttondiv {
  margin-top: 40px;
  width: 16%;
  display: flex;
  justify-content: space-between;
}
.mainholediv {
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.answercard {
  margin-top: 5px;
  cursor: pointer;
}
.answercarddiv {
  height: 220px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.solution {
  border: 2px solid #80ff80;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 100%;
}
.timeDuration {
  font-weight: 700;
  color: red;
}

/* media query for whatsapp */

@media (min-width: 1600px) and (max-width: 1799px) {
  .whatsapp {
    width: 78%;
    margin-left: 22vh;
    margin-bottom: 7vh;
  }
  .buttondiv {
    width: 18%;
  }
}

@media (min-width: 1500px) and (max-width: 1599px) {
  .whatsapp {
    width: 76%;
    margin-left: 21vh;
    margin-bottom: 7vh;
  }
  .buttondiv {
    width: 19%;
  }
}

@media (min-width: 1400px) and (max-width: 1499px) {
  .whatsapp {
    width: 74%;
    margin-left: 20vh;
    margin-bottom: 7vh;
  }
  .buttondiv {
    width: 20%;
  }
}
@media (min-width: 1300px) and (max-width: 1399px) {
  .whatsapp {
    width: 72%;
    margin-left: 19vh;
    margin-bottom: 7vh;
  }
  .buttondiv {
    width: 22%;
  }
}
@media (min-width: 1200px) and (max-width: 1299px) {
  .whatsapp {
    width: 70%;
    margin-left: 18vh;
    margin-bottom: 7vh;
  }
  .buttondiv {
    width: 23%;
  }
}
@media (min-width: 1100px) and (max-width: 1199px) {
  .whatsapp {
    width: 68%;
    margin-left: 17vh;
    margin-bottom: 7vh;
  }
  .buttondiv {
    width: 27%;
  }
}

@media (min-width: 1000px) and (max-width: 1099px) {
  .whatsapp {
    width: 66%;
    margin-left: 16vh;
    margin-bottom: 7vh;
  }
  .buttondiv {
    width: 27%;
  }
  .unitsCards {
    width: 97%;
  }
}

@media (min-width: 800px) and (max-width: 999px) {
  .whatsapp {
    width: 62%;
    margin-left: 13vh;
    margin-bottom: 7vh;
  }
  .buttondiv {
    width: 30%;
  }
  .unitsCards {
    width: 97%;
  }
}
@media (min-width: 700px) and (max-width: 799px) {
  .whatsapp {
    width: 59%;
    margin-left: 13vh;
    margin-bottom: 7vh;
  }

  .firstUnitsSubHeads {
    width: 20%;
  }
  .unitVideos {
    width: 95px;
    height: 30px;
    gap: 3px;
  }
  .unitsSubHeads {
    width: 65%;
    display: flex;
    justify-content: center;
    height: 100px;
    align-items: center;
  }
  .unitsSecSubHeads {
    display: flex;
    align-items: center;
    width: 75%;
    justify-content: space-around;
    height: 50px;
    border-radius: 20px;
    /* background-color: yellow; */
  }
  .unitVideoss {
    width: 80px;
    height: 20px;
  }
  .buttondiv {
    width: 38%;
  }
  .unitsCards {
    width: 96%;
  }
}
@media (min-width: 500px) and (max-width: 699px) {
  .firstUnitsSubHeads {
    width: 20%;
  }
  .unitVideos {
    width: 90px;
    height: 30px;
  }
  .circleOutLineVideosIcon {
    font-weight: 800;
    font-size: 10px;
  }
  .unitsSubHeads {
    width: 73%;
    display: flex;
    justify-content: flex-start;
    height: 100px;
    align-items: center;
  }
  .unitsSecSubHeads {
    display: flex;
    align-items: center;
    width: 91%;
    justify-content: space-around;
    height: 50px;
    border-radius: 20px;
    /* background-color: violet; */
  }
  .unitVideoss {
    width: 80px;
    height: 20px;
  }
  .videosPart {
    width: 58%;
    height: 25%;
  }
  /* .unitVideosMainDiv{
    background-color: #80ff80;
    width: 100%;
  } */
  .mainVideopartDiv {
    width: 100%;
    height: 50%;
  }
  .mediaplayer {
    height: 90%;
  }
  .buttondiv {
    width: 48%;
  }
  .unitheading {
    font-size: 15px;
  }
  .unitsCards {
    width: 95%;
  }
}
@media (min-width: 350px) and (max-width: 499px) {
  .firstUnitsSubHeads {
    width: 20%;
  }
  .unitVideos {
    width: 84px;
    height: 27px;
  }
  .circleOutLineVideosIcon {
    font-size: 10px;
  }
  .unitsSubHeads {
    width: 95%;
  }
  .unitsSecSubHeads {
    width: 100%;
    height: 45px;
    /* background-color: turquoise; */
  }
  .videosPart {
    width: 70%;
    height: 25%;
  }
  .description {
    display: flex;
    flex-direction: column;
    align-self: center;
  }
  .buttondiv {
    width: 73%;
    margin-bottom: 10px;
  }
  .unitheading {
    font-size: 10px;
  }
  .unitCard {
    font-size: 17px;
  }
  .units {
    height: 70px;
  }
  .unitTypo {
    font-size: 12px;
  }
  .rollno {
    background-color: var(--text-color);
    width: 80px;
    height: 30px;
    font-size: 13px;
  }
  .unitsCards {
    width: 92%;
  }
  .mediaplayer {
    width: 100%;
  }
  .mainVideopartDiv {
    width: 100%;
  }
  .sideVideoPar {
    margin-top: 1px;
  }
  .title {
    flex-direction: column;
  }
}
@media (min-width: 768px) and (max-width: 1180px) {
  .unitVideosMainDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .sideVideoPar {
    width: 85%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
    max-height: 300px;
  }
  .unitSideVidepar {
    width: 30%;
  }

  .mainVideopartDiv {
    width: 71%;
  }
}
@media (max-width: 820px) and (orientation: portrait),
  (max-width: 1180px) and (orientation: landscape) {
  .unitVideosMainDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mediaplayer {
    width: 100%;
    border-radius: 20px;
    border: 1px solid black;
    overflow: hidden;
    background-color: black;
  }
  .bb {
    max-height: 710px;
  }
}
@media (max-width: 834px) and (orientation: portrait),
  (max-width: 1194px) and (orientation: landscape) {
  .bb {
    max-height: 710px;
  }
  .sideVideoPar {
    width: 80%;
  }
}

.unitsSubjectsAndVideos {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.secondDivUnitsSubjectsAndVideos {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.unitSubSide {
  width: 100%;
  max-height: 78vh;
  overflow-y: auto;
}
