.container {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.appbar-congrats {
    height: 100px;
    padding: 38px 50px;
}
.congrats-container {
    background-image: url("../../assets/CongratsImages/confeti.png");
    width: 100%;
    height: 750px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.congrats-section {
    margin: 300px;  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
.image-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px;
}
.congrats-image {
    width: 205px;
    height: 205px;
}
.text-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 25px;
}
.text-section .cong-head {
    font-size: 50px;
    color: #000;
    padding: 10px;
}
.text-section .cong-para {
    font-size: 28px;
    color: #999999;
    padding: 10px;
}
.congrats-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 25px;
}
.cong-btn {
    color: #67C0F6;
    border-color: 1 px solid #82CCFE;
    background-color: #D3EDFF;
}
