.container {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.headText {
  text-align: center;
  padding: 3px 20px;
  font-size: 5px;
  background-color: #35affd;
  color: #fff;
}
.text-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10%;
  padding: 20px;
}
.para-text {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: grey;
  font-weight: 600;
}
.para-field {
  padding-left: 110px;
}
.input-field {
  padding: 15px;
}
.input-field .text-box {
  border: 1px solid #000000 !important;
  border-radius: 10px;
}
.btn-field {
  width: 50%;
}
.btn-field .dlt-btn {
  border: 1px solid #000000;
  border-radius: 10px;
}
.dlt-btn {
  width: 400px;
  background-color: #35affd !important;
  margin-bottom: 20px !important;
}
.entermobilenumber {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  margin-top: 5px;
  padding-left: 130px;
  gap: 10px;
  /* height: 150px; */
}
.small {
  border: 2px solid #ededed !important;
  width: 70px;
  border-radius: 7px;
}
.big {
  border: 2px solid #ededed;
  border-radius: 7px;
  width: 315px;
  height: 60px;
  font-size: 16px;
  color: grey;
}
.big:focus {
  outline: none;
  border-color: #35affd !important;
}

@media (max-width: 600px) {
  .text-box {
    text-align: center;
    margin: 20%;
    padding: 20px;
  }
}
