.main-subscripe {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 100px;
  padding: 30px;
}
.neetsubscriptionplane {
  list-style: none;
  margin-bottom: 25px;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.neetsubscriptionul {
  padding-left: 20px;
  margin-top: 70px;
  margin-left: 20px;
}
@media (min-width: 481px) and (max-width: 767px) {
  .main-subscripe {
    position: absolute;
    gap: 30px;
  }
}
@media (min-width: 350px) and (max-width: 480px) {
  .main-subscripe {
    position: absolute;
    margin-top: 120px;
    gap: 30px;
    width: 99%;
  }
  .neetsubscriptionplane {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .main-subscripe {
    position: absolute;
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    gap: 30px;
  }
}
