/* .ReactQuil.ql-container.ql-snow {
  border: 1px hidden white !important;
} */
.ReactQuillContainer {
  border: hidden;
}
.neetquizenameyearfirst {
  display: block;
}
.ql-container.ql-snow {
  border: none !important;
}

@media (min-width: 580px) and (max-width: 699px) {
  .neetquizenameyear {
  }
}
