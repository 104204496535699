.textfield {
  width: 400px;
}
.welcomback {
  text-align: center;
  font-size: 40px;
  color: var(--bgcolor);
}
.belownavbarinsingin {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  /* height: 500px; */
  margin-top: 40px;
  justify-content: space-around;
}
.Babystudy {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
}
.Babystudys {
  width: 100%;
  height: 300px;
}
.icon {
  position: absolute;

  margin-left: 379px;
}
.entermobilenumberbox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 5px;
  gap: 10px;
  /* height: 150px; */
}
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.holldiv {
  display: flex;
  flex-direction: column;
  margin-top: 120px;
}
.textsmall {
  border: 2px solid #ededed !important;
  width: 70px;
  border-radius: 7px;
}
.textbig {
  border: 2px solid #ededed;
  border-radius: 7px;
  width: 315px;
  height: 60px;
  font-size: 16px;
  color: grey;
}
.footer {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  gap: 10px;
  /* height: 120px; */
}
.footerSec {
  width: 30%;
  background-color: yellow;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  gap: 10px;
}
.holldiv {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.footercolor {
  color: #35affc;
}
.signBtn {
  width: 400px;
  margin-bottom: 20px !important;
}
.textbig:focus {
  outline: none;
  border-color: #35affc !important;
}

@media screen and (max-width: 480px) {
  .textsmall {
    width: 65px;
  }
  .textbig {
    border: 2px solid #ededed;
    border-radius: 7px;
    width: 270px;
    height: 60px;
    font-size: 16px;
    color: grey;
  }
}
#mobileNo::placeholder {
  font-size: 13px; /* Adjust this for the placeholder size */
}
