.mainDivSubNav {
  display: flex;
  /* background-color: var(--text-color); */
  justify-content: center;
  align-items: center;
  height: 60px;
  /* border-bottom-right-radius: 26%;
  border-bottom-left-radius: 26%; */
}

.colorchanged {
  height: 120px;
  /* background-color: #287f87; */
}

.gillltrdiv {
  width: 250px;
  display: flex;
  justify-content: flex-end;
}

.secDiv {
  width: 92%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
}

.menuicons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.books {
  width: 150px;
  height: 250px;

}

.gillLtr {
  color: white;

}

.gillLtrr {
  color: white;
  text-align: center;
  
}

.gillltrme {
  color: white;

}

.onBoardings {
  display: flex;
  position: absolute;
  height: 30px;
  border-radius: 29px;
  width: 150px;
  justify-content: center;
  align-items: center;
  top: 100px;
  left: 45.5%;
}

.unitsBookMarImeges {
  background-image: url(./images/Rectangle\ 45.png);
  width: 190px;
  height: 215px;
  margin-top: 90px;
  background-size: 100% 100%;
}

.unitsBookStd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.unitsBookLogo {
  width: 50px;
  margin: "5px"
}

.unitMarNam {
  margin: 3px;
  font-weight: 300;
  font-size: 14px;
}

.unitMarNams {
  margin: 2px;
  font-size: 17px;
}

.appNameAndUnitsNames{
  width: 150px;
  margin-left: 110px;

}
@media (min-width: 1570px) and (max-width: 1750px){
 .appNameAndUnitsNames{
   width: 150px;
   margin-left: 140px;
}
}
 @media (min-width: 1280px) and (max-width: 1570px){
 .appNameAndUnitsNames{
  margin-left: 165px;
   width: 150px;
}
 
}

@media (min-width: 1025px) and (max-width: 1279px) {
  
  .appNameAndUnitsNames{
   width: 150px;
   margin-left: 163px;
  }

  
  .unitsBookMarImeges {
    width: 170px;
    height: 205px;
    margin-top: 87px;
  }

  .unitsBookLogo {
    width: 45px;

  }

  .unitMarNams {

    font-size: 15px;
  }

  .unitMarNam {
    font-size: 13px;
  }

  .gillltrdiv {
    width: 230px;
    display: flex;
    justify-content: flex-end;
  }

}

@media (min-width: 768px) and (max-width: 1024px) {
   
  .appNameAndUnitsNames{
    width: 150px;
    margin-left: 163px;
   }
  .unitsBookMarImeges {
    width: 150px;
    height: 198px;
    margin-top: 80px;
  }
  .unitsBookLogo {
    width: 41px;

  }
  .unitMarNams {

    font-size: 14px;
  }

  .unitMarNam {
    font-size: 12px;
  }

  .gillltrdiv {
    width: 210px;
    display: flex;
    justify-content: flex-end;
  }

}

@media (min-width: 481px) and (max-width: 767px) {

  .appNameAndUnitsNames{
    width: 150px;
    margin-left: 170px;
   }

  .unitsBookMarImeges {
    width: 140px;
    height: 180px;
    margin-top: 61px;

  }

  .unitsBookLogo {
    width: 38px;

  }

  .unitMarNams {

    font-size: 13px;
  }

  .unitMarNam {
    font-size: 11px;
  }

  .gillltrdiv {
    width: 200px;
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 480px) {


  .appNameAndUnitsNames{
    width: 150px;
    margin-left: 163px;
   }


  .unitsBookMarImeges {
    width: 140px;
    height: 175px;
    margin-top: 57px;

  }

  .unitsBookLogo {
    width: 36px;

  }

  .unitMarNams {

    font-size: 12px;
  }

  .unitMarNam {
    font-size: 10px;
  }

  .gillltrdiv {
    width: 190px;
    display: flex;
    justify-content: flex-end;
  }

}

/* glowing-btn */

/* CSS */
.upgrade-btn {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.upgrade-btn:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-upgrade-btn 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-upgrade-btn {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.upgrade-btn:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #DAA520;
  left: 0;
  top: 0;
  border-radius: 10px;
}