.navbar {
  background-color: var(--bgcolor);
  display: flex;
  justify-content: center;
  border-bottom-right-radius: 25%;
  border-bottom-left-radius: 25%;
  height: 100px;

}

.onboarding {
  background-color: whitesmoke;
  color: var(--bgcolor);

}

.glogo {
  width: 60px;
  height: 60px;
  display: flex;
  align-self: center;
}

.gillgallletter {
  display: flex;
  align-self: center;
  font-size: 30px;
  color: #ffffff;
}

.navdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btnonboarding {
  position: relative;
  margin-top: 100px;
  border: solid 2px;
  border-color: var(--bgcolor);
  color: var(--bgcolor);
  width: 120px;
  height: 30px;
  background-color: #ffffff;
}

.twoheading {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  gap:20px;
  margin-top: 150px;
}

.termsandservice {
  text-align: center;
}

.groupdiss {
  width: 100%;
  height: 300px;
}

.terms {
  color: var(--bgcolor);
  cursor: pointer;
}
.onboardingSecDIv{
  justify-content: center;
  display: flex;
  align-items: center;
  width: 97%;
}