#otpfield {
  display: block;
  /* margin: auto; */
  margin-left: 15px;
  border: none;
  padding: 0;
  width: 10.2ch;
  /* width:280px; */
  background: repeating-linear-gradient(
      116deg,
      dimgrey 0,
      dimgrey 1ch,
      transparent 0,
      transparent 1.5ch
    )
    0 97%/ 10ch 2px no-repeat;
  font:
    51px claimcheck sans mono,
    consolas,
    monospace;
  letter-spacing: 0.68ch;
  /* background-color: teal; */
}
.manin {
  background-color: green;
  margin-left: -8px;
}
#otpfield:focus {
  outline: none;
  color: green;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
@media (max-width: 770px) {
  #mob {
    height: 100px;
  }

  #otpfield {
    font-size: 48px;
    margin-top: 20px;
  }
  #btn {
    font-size: 17px;
    width: 15vh;
    height: 4vh;
  }
}

@media (max-width: 600px) {
  #otpfield {
    font-size: 40px;
    margin-top: 20px;
  }

  #btn {
    font-size: 12px;
    width: 10vh;
    height: 3vh;
  }
}




