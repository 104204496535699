.threetextfields ::placeholder {
  font-size: 11px; /* Adjust this for the placeholder size */
}

.creatingdiv {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  margin-top: 130px;
}

.creativebutton {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
}

.stdimages {
  width: 100%;
}

.registertextfield {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.threetextfields {
  /* width: 400px;
    border: solid 2px; */
  border: 2px solid black !important;
  border-radius: 7px;
  width: 390px;
}

.letstartbtn {
  width: 400px;
  border: solid 2px;
  height: 40px;
}

@media (min-width: 480px) and (max-width: 600px) {
  .threetextfields {
    width: 350px;
  }

  .letstartbtn {
    width: 350px;
  }
}

@media (min-width: 350px) and (max-width: 479px) {
  .threetextfields {
    width: 310px;
  }

  .letstartbtn {
    width: 310px;
  }

  .creatacc {
    font-size: 18px;
  }
}
