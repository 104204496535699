.player-wrapper {
  width: auto;
  height: auto;
}
.react-player {
  padding-top: 56.25%;
  position: relative;
}

.react-player > div {
  position: absolute;
}
.wrapper {
  position: relative;
  padding-top: 56.25%;
}
.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.preuploadimgtagbox {
  height: 100%;
  width: 15%;
  background-color: yellow;
}
.preuploadimgtag {
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .preuploadimgtagbox {
    height: 100%;
    width: 25%;
    background-color: blue;
  }
  .preuploadimgtag {
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .preuploadimgtagbox {
    height: 100%;
    width: 50%;
    background-color: yellow;
  }
  .preuploadimgtag {
    width: 100%;
    height: 100%;
  }
}
/* @media (max-width: 480px) {
  .preuploadimgtagbox {
    width: 50%;
    background-color: blue;
  }
  .preuploadimgtag {
    width: 100%;
    background-color: springgreen;
  }
} */
.ban {
  height: 280px;
  width: 100%;
  object-fit: contain;
}
@media (min-width: 320px) and (max-width: 479px) {
  .preuploadimgtagbox {
    display: none;
  }
}
@media (max-width: 425px) {
  .wholebox {
    padding-top: 0px !important;
  }
  .ban {
    height: 110px;
    padding-bottom: 0px !important;
    width: 100%;
  }
}
@media (min-width: 426px) and (max-width: 500px) {
  .wholebox {
    padding-top: 0px !important;
  }
  .ban {
    height: 140px;
    padding-bottom: 0px !important;
    width: 100%;
  }
}
@media (min-width: 601px) and (max-width: 720px) {
  .ban {
    height: 220px;
    padding-bottom: 0px !important;
    width: 100%;
  }
  .wholebox {
    padding-top: 0px !important;
  }
}
@media (min-width: 501px) and (max-width: 600px) {
  .ban {
    height: 170px;
    padding-bottom: 0px !important;
    width: 100%;
  }
  .wholebox {
    padding-top: 0px !important;
  }
}
