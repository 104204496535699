.sb-headings {
  margin-top: 100px !important;
  position: absolute;
  color: #ffffff;
  font-size: 32px !important;
  font-family: "public-sans";
  font-weight: 700 !important;
}

.sb-months {
  margin-top: 180px !important;
}

.sb-icons {
  margin-top: 170px !important;
}

.sb-years {
  margin-top: 180px !important;
}

.sb-subscriptions {
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 70px;
  margin-bottom: 5px;
  overflow: hidden;
}

@media (min-width: 350px) and (max-width: 480px) {
  .sb-headings {
    margin-top: 70px !important;
    font-size: 14px !important;
  }

  .sb-months {
    margin-top: 140px !important;
  }

  .sb-icons {
    margin-top: 135px !important;
    /* font-size: 2px !important;
    size: 0ch; */
  }

  .sb-years {
    margin-top: 140px !important;
  }

  .sb-subscriptions {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: absolute;
    margin-top: 25px;
    width: 100%;
    align-items: center;
    padding: 20px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .sb-headings {
    margin-top: 70px !important;
    font-size: 18px !important;
  }

  .sb-months {
    margin-top: 140px !important;
  }

  .sb-icons {
    margin-top: 135px !important;
  }

  .sb-years {
    margin-top: 140px !important;
  }

  .sb-subscriptions {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: absolute;
    margin-top: 25px;
    width: 100%;
    align-items: center;
    padding: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .sb-headings {
    margin-top: 90px !important;
    font-size: 29px !important;
  }

  .sb-months {
    margin-top: 180px !important;
  }

  .sb-icons {
    margin-top: 170px !important;
  }

  .sb-years {
    margin-top: 180px !important;
  }

  .sb-subscriptions {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: absolute;
    margin-top: 25px;
    width: 100%;
    align-items: center;
    padding: 20px;
  }
}
