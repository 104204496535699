
.onboarding {
  /* border: solid 2px var(--bgcolor) ; */
  /* border-color: var(--bgcolor); */
  color: #35affd;
  display: flex;
  background-color: #ffffff;
  align-self: flex-end;
  height: 30px;
  border-radius: 2px;
  /* width: 30%; */
  justify-content: center;
  align-items: center;
  padding: 7px;
  border: solid 2px #35affd;
}
