.subs-heading {
  margin-top: 100px !important;
}

.subs-month {
  margin-top: 180px !important;
}

.subs-icon {
  margin-top: 170px !important;
}

.subs-year {
  margin-top: 180px !important;
}

.stateboard {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  position: fixed;
  margin-top: 685px;
  margin-bottom: 10px;
}

@media (min-width: 481px) and (max-width: 767px) {
  .subs-heading {
    margin-top: 70px !important;
    font-size: 28px !important;
    text-align: center;
  }

  .subs-month {
    margin-top: 140px !important;
  }
  
  .subs-icon {
    margin-top: 135px !important;
  }
  
  .subs-year {
    margin-top: 140px !important;
  }

  .stateboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    margin-top: 1700px;
    width: 80%;
    padding: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .subs-heading {
    margin-top: 90px !important;
  }

  .subs-month {
    margin-top: 180px !important;
  }
  
  .subs-icon {
    margin-top: 170px !important;
  }
  
  .subs-year {
    margin-top: 180px !important;
  }
  
  .stateboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    margin-top: 1700px;
    width: 100%;
    padding: 20px;
  }
}

