.mapcards {
  width: 30%;
  height: 60px;
  margin-bottom: 30px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.choosecards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.cardes {
}
.seconddiv {
  width: 100%;
  display: flex;
  height: 425px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.chooseclass {
  /* background-color: yellow; */
  /* font-size: 28px; */
}
.twodivinside {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  /* height: 700px; */
  margin-top: 150px;
}
.choosebtn {
  width: 390px;
}
.footbtn {
  margin-top: 15px;

  /*     
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center; */
  /* width: 20%; */
}
/* .onboarding {
  background-color: yellow;
  font-size: 14px !important;
} */

@media screen and (min-width: 350px) and (max-width: 400px) {
  .footbtn {
  }
  .choosebtn{
    width: 350px;
    margin-bottom: 30px !important;
  }
}
