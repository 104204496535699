.container {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; 
    align-items: left; 
    padding: 20px;
}
.quiz-container {
    margin: 5%;
}
.quiz-appbar {
    background-color: "#35affd";
    height: "100px";
}
.exam-questions {
    padding: 10px;
    text-align: center;
    margin: 50px;
    font-size: 45px;
}
.quiz-cardsections {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.quiz-cards {
    padding: 20px;
}
.quiz-maincard {
    width: 1300px;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    padding: 10px 70px;
}
.quiz-maincard .ans-list {
    font-size: 30px;
    font-weight: 600;
}
.next-btn {
    color: #5DA6FC;
}
.timer-option  {
    display: flex;
    flex-direction: column;
    float: "left";
}
.container-timer  {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.btn-timer {
    display: flex;
    flex-direction: column;
    float: left;
    padding: 20px;
}
.timer-option {
    margin: 0;
}