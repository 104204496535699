.circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
  
  .circle {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #e6e6e6;
  }
  
  .fill {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: green; /* Color for the fill */
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  
  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: white;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  
  .percent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: black;
  }

.ReactQuillContainer .ql-container.ql-snow {
  border: 1px solid white !important; 
  /* background-color: yellow; */
}
