.container {
    width: 100%;
    margin: 10px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; 
    align-items: left; 
}
.mock-appbar {
    width: 100%;
}
.headtext {
    padding: 20px;
}
.container-exam {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 150px;
}
.exam-section {
    display: flex;
    flex-direction: column;
}
.count-col {
    display: flex;
    flex-direction: row;
    column-count: 2;
    padding: 17px 60px;
}
.main-card {
    width: 1300px;
    height: 213px;
    gap: 3px;
}
.exam-heading {
    font-size: 40px;
    color: #000;
    padding: 25px 70px;
    text-align: left;
}
.card-subtext {
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: left;
}
.subject-title {
    font-size: 25px;
    color: #000;
    padding: 10px;
}
.subject-para {
    font-size: 20;
    padding: 10px;
}
.card-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}