/* <----- Nav Bar responsived   ------> */

/* 
<----------------------------> */

.gillmenu {
  background-image: url(./Images/bg.png);
  /* height: 79vh;    */
  width: 100%;
  background-repeat: no-repeat;
  margin-top: 130px;
  display: flex;
  justify-content: center;
  background-size: contain;
}

.letterclr {
  font-size: 100px;
  text-shadow:
    -2px -2px 0 #000,
    2px -2px 0 #000,
    -2px 2px 0 #000,
    2px 2px 0 #000;
  letter-spacing: 4px;
  color: white;
  margin: 0px;
}
.gillmenucontent {
  width: 85%;
}
.Landingwrapper {
  display: flex;
  width: 100%;
}

.seconddivwith {
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.divwith {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mobile {
  width: 95%;
  height: 689px;
}

.lettercontent {
  font-size: 36px;
  width: 95%;
  /* text-align: justify; */
}
.colornames {
  cursor: pointer;
}

.gillgallcolor {
  color: #ff1b6d;
  font-weight: 800;
  width: 222px;
}
/* .single-line-tamil {
  white-space: w;
} */
.ourappsecon {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 70px;
}
.ourapplicationdiv {
  width: 85%;
}

.ourapp {
  width: 400px;
  display: flex;
  justify-content: flex-start;
}
.ourappimgTamil {
  width: 500px;
  height: 60px;
  display: flex;
  justify-content: flex-start;
}
.playerdiv {
  width: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
}
.ourappdemo {
  width: 100%;
  display: flex;
  justify-content: center;
}
.appdemoes {
  width: 85%;
}
.ourappdemo .appdemoes .appdemoTamil {
  height: 70px;
  width: 25%;
}
.demovideossec {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

/* <---- Appbar Mobile  view responsive----> */
@media screen and (max-width: 750px) {
  .menuicon {
    display: block;
  }
}
.features {
  color: white;
}

@media screen and (min-width: 350px) and (max-width: 480px) {
  .gillmenu {
    background-image: url(./Images/mob-bg1.png);
    width: 100%;
    background-size: cover;
    margin-top: 52px;
  }
  .namecolor {
    width: 100%;
    justify-content: center;
    display: flex;
    height: 70px;
    align-items: center;
  }
  .letterclr {
    font-size: 27px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .divwith {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .lettercontent {
    font-size: 17px;
    text-align: justify;
    width: 80%;
  }
  .colorchange {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .namecolors {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .colornames {
    width: 140px;
    display: flex;
  }
  .seconddivwith {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .mobile {
    width: 60%;
    height: 220px;
  }
  .Landingwrapper {
    display: flex;
    flex-direction: column;
  }
  .ourappsecon {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .ourapp {
    width: 220px;
  }
  .playerdiv {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -20px;
  }
}

@media screen and (min-width: 481px) and (max-width: 599px) {
  .Landingwrapper {
    display: flex;
    flex-direction: column;
  }
  .gillmenu {
    width: 100%;
    background-size: cover;
  }
  .namecolor {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
  .letterclr {
    font-size: 62px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .divwith {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .colorchange {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .lettercontent {
    font-size: 22px;
    text-align: justify;
    width: 80%;
  }
  .namecolors {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .colornames {
    display: flex;
    width: 180px;
  }
  .seconddivwith {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2px;
  }
  .mobile {
    height: 250px;
    width: 64%;
  }
  .ourappsecon {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .ourapp {
    width: 280px;
  }
  .playerdiv {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -20px;
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
  .Landingwrapper {
    display: flex;
    flex-direction: column;
  }
  .gillmenu {
    width: 100%;
    background-size: cover;
    margin-top: 85px;
  }
  .namecolor {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .letterclr {
    font-size: 80px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .divwith {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .colorchange {
    width: 90%;
    display: flex;
    justify-content: center;
  }
  .lettercontent {
    font-size: 25px;
    text-align: justify;
    width: 80%;
  }
  .namecolors {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .colornames {
    display: flex;
    width: 180px;
  }
  .seconddivwith {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2px;
  }
  .mobile {
    height: 390px;
    width: 65%;
  }
  .ourappsecon {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .ourapp {
    width: 280px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .namecolor {
    display: flex;
    height: 100px;
    justify-content: center;
    align-items: center;
  }
  .letterclr {
    margin: 0;
  }
  .divwith {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Landingwrapper {
    display: flex;
    flex-direction: column;
  }
  .gillmenu {
    /* width: 100%; */
    background-size: cover;
    margin-top: 85px;
  }
  .colorchange {
    width: 90%;
    display: flex;
    justify-content: center;
  }
  .lettercontent {
    font-size: 29px;
    text-align: justify;
    width: 80%;
  }
  .namecolors {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .colornames {
    display: flex;
  }
  .seconddivwith {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .mobile {
    height: 580px;
    width: 70%;
  }
  .ourappsecon {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .ourapp {
    width: 350px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .letterclr {
    font-size: 88px;
    text-shadow:
      -2px -2px 0 #000,
      2px -2px 0 #000,
      -2px 2px 0 #000,
      2px 2px 0 #000;

    letter-spacing: 4px;
    color: white;
    margin: 0px;
  }
  .gillmenucontent {
    width: 85%;
  }
  .Landingwrapper {
    display: flex;
    width: 100%;
  }

  .seconddivwith {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .divwith {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .mobile {
    width: 95%;
    height: 589px;
  }

  .lettercontent {
    font-size: 32px;
    width: 95%;
    text-align: justify;
  }
  .colornames {
    cursor: pointer;
  }

  .gillgallcolor {
    color: #ff1b6d;
    font-weight: 800;
    width: 222px;
  }
  .ourappsecon {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .ourapplicationdiv {
    width: 85%;
  }

  .ourapp {
    width: 400px;
    display: flex;
    justify-content: flex-start;
  }
}
/* <------- Learn With Video Classes  ---------> */

.learnvideobg {
  background-image: url(./Images/blueBG.png);
  width: 100%;
  background-repeat: no-repeat;
  margin-top: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
}
.learncontent {
  display: flex;
  align-items: center;
  gap: 15px;
}
.divwidth {
  width: 85%;
}
.learncontain {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.leftsidecontent {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.learnright {
  display: flex;
  justify-content: center;
  width: 40%;
}
.learnrightimage {
  width: 280px;
  height: 287px;
}
.learnclasses {
  font-size: 39px;
  color: white;
  display: flex;
}
.videoslearn {
  display: flex;
  justify-content: center;
  width: 100%;
}
.fontsize {
  font-size: 29px;
  color: white;
}
.bulleticon {
  color: white;
}

@media screen and (max-width: 480px) {
  .learnvideobg {
    background-image: url(./Images/blueBG.png);
    width: 100%;
    background-repeat: no-repeat;
    margin-top: 35px;
    display: flex;
    justify-content: flex-end;
    background-size: 100% 100%;
  }
  .fontsize {
    font-size: 16px;
  }
  .learncontain {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .learnrightimage {
    width: 216px;
    height: 154px;
  }
  .learnclasses {
    font-size: 22px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .leftsidecontent {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .videoslearn {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 481px) and (max-width: 900px) {
  .learnvideobg {
    background-image: url(./Images/blueBG.png);
    width: 100%;
    background-repeat: no-repeat;
    margin-top: 35px;
    display: flex;
    justify-content: flex-end;
    background-size: 100% 100%;
  }
  .fontsize {
    font-size: 19px;
  }
  .learncontain {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
  }
  .learnrightimage {
    width: 219px;
    height: 158px;
  }
  .learnclasses {
    font-size: 24px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .leftsidecontent {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .videoslearn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* <--------------------------Question and Answers container        -----------------------------> */
.ouestionanswbgs {
  background-image: url(./Images/whiteBG.png);
  width: 100%;
  background-repeat: no-repeat;
  margin-top: 35px;
  display: flex;
  justify-content: flex-end;
  background-size: 100% 100%;
}
.learncontents {
  display: flex;
  align-items: center;
  gap: 15px;
}
.divwidths {
  width: 85%;
}
.learncontains {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.leftsidecontents {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.learnrights {
  display: flex;
  justify-content: center;
  width: 40%;
}
.imagelearnrights {
  width: 280px;
  height: 287px;
}
.learnclasseses {
  font-size: 39px;
  display: flex;
}
.videoslearns {
  display: flex;
  justify-content: center;
  margin-top: -32px;
}
.fontsizess {
  font-size: 29px;
  color: #000000;
}
.bulleticons {
  color: #000000;
}
/* <-------------------------- Question and Answers  max with 480px -----------------------------> */

@media screen and (max-width: 480px) {
  .ouestionanswbgs {
    background-image: url(./Images/whiteBG.png);
    width: 100%;
    background-repeat: no-repeat;
    margin-top: 35px;
    display: flex;
    justify-content: flex-end;
    background-size: 100% 100%;
  }
  .fontsizess {
    font-size: 16px;
  }
  .learncontains {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .imagelearnrights {
    width: 216px;
    height: 154px;
  }
  .learnclasseses {
    font-size: 22px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .leftsidecontents {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .videoslearns {
    display: flex;
    justify-content: center;
    margin-top: 1px;
  }
}
@media screen and (min-width: 370px) and (max-width: 412px) {
  .videoslearns {
    margin-top: 1px;
  }
}
@media screen and (min-width: 481px) and (max-width: 900px) {
  .learnvideobgs {
    background-image: url(./Images/whiteBG.png);
    width: 100%;
    background-repeat: no-repeat;
    margin-top: 35px;
    display: flex;
    justify-content: flex-end;
    background-size: 100% 100%;
  }
  .fontsizess {
    font-size: 19px;
  }
  .learncontains {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .learnrightimages {
    width: 219px;
    height: 158px;
  }
  .learnclasseses {
    font-size: 24px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .leftsidecontents {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .videoslearns {
    display: flex;
    justify-content: center;
    margin-top: 1px;
  }
  .learnrights {
  }
  .imagelearnrights {
    width: 219px;
    height: 158px;
  }
}
/* <-----------  Model Question Papers for all Subjects     --------------> */
.learnvideobge {
  background-image: url(./Images/blueBG.png);
  width: 100%;
  background-repeat: no-repeat;
  margin-top: 35px;
  display: flex;
  justify-content: flex-end;
  background-size: 100% 100%;
}
.learncontente {
  display: flex;
  align-items: center;
  gap: 15px;
}
.divwidthe {
  width: 85%;
}
.learncontaine {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.leftsidecontente {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.learnrighte {
  display: flex;
  justify-content: center;
  width: 40%;
}
.learnrightimagee {
  width: 280px;
  height: 287px;
}
.learnclassese {
  font-size: 39px;
  color: white;
  display: flex;
  /* In your external CSS file */
}
.videoslearne {
  display: flex;
  justify-content: center;
}
.fontsizee {
  font-size: 29px;
  color: white;
}
.bulleticone {
  color: white;
}

/* <--------------------------Model Question Papers for all Subjects max with 480px -----------------------------> */
@media screen and (max-width: 480px) {
  .learnvideobge {
    background-image: url(./Images/blueBG.png);
    width: 100%;
    background-repeat: no-repeat;
    margin-top: 35px;
    display: flex;
    justify-content: flex-end;
    background-size: 100% 100%;
  }
  .fontsizee {
    font-size: 16px;
  }
  .learncontaine {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .learnrightimagee {
    width: 216px;
    height: 154px;
    margin-bottom: 8px;
  }
  .learnclassese {
    font-size: 22px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .leftsidecontente {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .videoslearne {
    display: flex;
    justify-content: center;
  }
}
@media screen and (min-width: 481px) and (max-width: 900px) {
  .learnvideobge {
    background-image: url(./Images/blueBG.png);
    width: 100%;
    background-repeat: no-repeat;
    margin-top: 35px;
    display: flex;
    justify-content: flex-end;
    background-size: 100% 100%;
  }
  .fontsizee {
    font-size: 19px;
  }
  .learncontaine {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .learnrightimagee {
    width: 219px;
    height: 158px;
    margin-bottom: 8px;
  }
  .learnclassese {
    font-size: 24px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .leftsidecontente {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .videoslearne {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* <----------Model Question Papers for all Subjects min with 901px  max with 1390px ---------> */

@media (min-width: 901px) and (max-width: 1390px) {
  .learnclassese {
    font-size: 33px;
    color: white;
    display: flex;
  }
}
/* <-------------- Choice Based Questions   -----------------> */

.ouestionanswbgsa {
  background-image: url(./Images/whiteBG.png);
  width: 100%;
  background-repeat: no-repeat;
  margin-top: 35px;
  display: flex;
  justify-content: flex-end;
  background-size: 100% 100%;
}
.learncontentsa {
  display: flex;
  align-items: center;
  gap: 15px;
}
.divwidthsa {
  width: 85%;
}
.learncontainsa {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
.leftsidecontentsa {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.learnrightsa {
  display: flex;
  justify-content: center;
  width: 40%;
}
.imagelearnrightsa {
  width: 280px;
  height: 287px;
  color: #000000;
}
.learnclassesesa {
  font-size: 39px;
  display: flex;
}
.videoslearnsa {
  display: flex;
  justify-content: center;
}
.fontsizessa {
  font-size: 29px;
  color: #000000;
}
.bulleticonsa {
  color: #000000;
}
/* <-------------------------- Choice Based Questions max with 480px -----------------------------> */
@media screen and (max-width: 480px) {
  .ouestionanswbgsa {
    background-image: url(./Images/whiteBG.png);
    width: 100%;
    background-repeat: no-repeat;
    margin-top: 35px;
    display: flex;
    justify-content: flex-end;
    background-size: 100% 100%;
  }
  .fontsizessa {
    font-size: 16px;
  }
  .learncontainsa {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .imagelearnrightsa {
    width: 216px;
    height: 154px;
  }
  .learnclassesesa {
    font-size: 22px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .leftsidecontentsa {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .videoslearnsa {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 370px) and (max-width: 412px) {
  .videoslearnsa {
    margin-top: 1px;
  }
}
@media (min-width: 481px) and (max-width: 900px) {
  .learnvideobgsa {
    background-image: url(./Images/whiteBG.png);
    width: 100%;
    background-repeat: no-repeat;
    margin-top: 35px;
    display: flex;
    justify-content: flex-end;
    background-size: 100% 100%;
  }
  .fontsizessa {
    font-size: 19px;
  }
  .learncontainsa {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .learnrightimagesa {
    width: 219px;
    height: 158px;
  }
  .learnclassesesa {
    font-size: 26px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .leftsidecontentsa {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .videoslearnsa {
    display: flex;
    justify-content: center;
  }
  .imagelearnrightsa {
    width: 216px;
    height: 154px;
  }
}
/* <------APP DEMO VIDEO ------>      */

@media screen and (min-width: 1581px) and (max-width: 1730px) {
  .appdemoes {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .demoapp {
    width: 310px;
  }
}
@media screen and (min-width: 1281px) and (max-width: 1580px) {
  .appdemoes {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .demoapp {
    width: 350px;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .appdemoes {
    width: 100%;
    display: flex;
    justify-content: center;

    margin-top: 35px;
  }
  .demoapp {
    width: 350px;
  }
}
.appdemoes {
  width: 100%;
  display: flex;
  justify-content: center;

  margin-top: 35px;
}
.demoapp {
  width: 350px;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .appdemoes {
    width: 100%;

    display: flex;
    justify-content: center;
    margin-top: 680px;
  }
  .demoapp {
    width: 350px;
  }
}
@media screen and (min-width: 481px) and (max-width: 767px) {
  .appdemoes {
    width: 100%;

    display: flex;
    justify-content: center;
    margin-top: 920px;
  }
  .demoapp {
    width: 350px;
  }
}

@media screen and (max-width: 480px) {
  .appdemoes {
    width: 100%;

    display: flex;
    justify-content: center;
    margin-top: 265px;
  }
  .demoapp {
    width: 250px;
    display: flex;
    justify-content: center;
  }
}
.appdemoes {
  width: 85%;
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  padding-top: 40px;
  padding-bottom: 55px;
}
.demoapp {
  width: 250px;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 370px) and (max-width: 550px) {
  .demoapp {
    width: 110px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
  .appdemoes {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 7px;
  }
}
@media screen and (min-width: 250px) and (max-width: 369px) {
  .appdemoes {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .demoapp {
    width: 110px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
}

@media screen and (min-width: 551px) and (max-width: 900px) {
  .demoapp {
    width: 210px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
  .appdemoes {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 7px;
  }
}

.Apps {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

/* <---- App Demo Videos Resposive  -----> */

/* <----- Footer Div-----> */
.footercolor {
  background-color: #35affd;
  display: flex;
  margin-top: 80px;
  justify-content: space-evenly;
}
.footericoncolor {
  color: white;
  width: 100px;
}
.footersicon {
  display: flex;
  width: 23%;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.location {
  display: flex;
  gap: 2px;
  width: 10%;
  justify-content: left;
}
.locate {
  display: flex;
  gap: 2px;
  width: 12%;
}

.address {
  color: white;
  font-size: 20px;
}
.addressfontsize {
  font-size: 17px;
  flex-direction: column;
  display: flex;
}
.locationicon {
  color: white;
  margin-top: 30px;
}
.topsocialicon {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.bottomsocialicon {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.social {
  color: white;
  font-size: 30px;
  cursor: pointer;
}

/* <----  footer div mobile mail playstore     ------> */
.fottersocialmediadiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.footerdiv {
  display: flex;
  justify-content: space-evenly;
  background-color: #35affd;
  align-items: center;
  padding-top: 20px;
  flex-direction: column;
  width: 100%;
}
.playstorfooters {
  width: 150px;
  cursor: pointer;
}
.socials {
  color: white;
  font-size: 50px;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .socials {
    font-size: 30px;
  }
  .playstorfooters {
    width: 120px;
  }
}
@media (min-width: 481px) and (max-width: 767px) {
  .socials {
    font-size: 40px;
  }
  .playstorfooters {
    width: 150px;
  }
}

/* <------ copyright  -------> */
.copyright {
  display: flex;
  background-color: #35affd;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: row;
  height: 40px;
}
.copyrightsecdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 71%;
}
.copyrighticon {
  font-size: 15px;
}
.finalfooter {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #35affd;
}
.horizontalLine {
  width: 71%;
  height: 0px;
  color: gray;
}
.horizontalLinemain {
  width: 100%;
}
.privacypolicy {
  color: "white";
  margin-right: 8px;
  cursor: pointer;
}

/* <----- Footer Div Responsive-----> */

@media (min-width: 900px) and (max-width: 1070px) {
  .horizontalLine {
    width: 100%;
  }
  .copyright {
    height: 50px;
    display: flex;
  }
  .copyrightsecdiv {
    width: 100%;
  }
  .texbox {
    width: 60%;
    text-align: center;
  }
  .footercolor {
    display: flex;
    justify-content: space-between;
  }
  .getfullyear {
    width: 40%;
    text-align: center;
  }
  .fottersocialmediadiv {
    width: 98%;
  }
  .location {
    width: 28%;
  }
  .locate {
    width: 28%;
  }
  .fottersocialmediadiv {
    display: flex;
    justify-content: space-between;
  }
}
@media (min-width: 768px) and (max-width: 900px) {
  .fottersocialmediadiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .horizontalLine {
    width: 99%;
  }
  .copyright {
    height: 60px;
    /* background-color: #ff1b6d; */
  }
  .copyrightsecdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: column;
    height: 50px;
  }
}

@media (min-width: 700px) and (max-width: 767px) {
  .footersicon {
    width: 190px;
  }
  .address {
    font-size: 20px;
  }
  .footercolor {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin-top: 80px;
  }
  .locationicon {
    margin-top: 16px;
  }
  .location {
    display: flex;
    gap: 2px;
    width: 50%;
  }
  .locate {
    display: flex;
    width: 50%;
  }

  .horizontalLine {
    width: 99%;
  }
  .fottersocialmediadiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .copyright {
    height: 60px;
  }
  .copyrightsecdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: column;
    height: 50px;
  }
}
@media (min-width: 599px) and (max-width: 699px) {
  .fottersocialmediadiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .horizontalLine {
    width: 99%;
  }
  .copyright {
    height: 80px;
  }
  .copyrightsecdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: column;
    height: 50px;
  }
  .texbox {
    text-align: center;
  }
  .footersicon {
    width: 190px;
  }
  .address {
    font-size: 20px;
  }
  .footercolor {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin-top: 80px;
  }
  .locationicon {
    margin-top: 16px;
  }
  .location {
    display: flex;
    gap: 2px;
    width: 50%;
  }
  .locate {
    display: flex;
    width: 50%;
  }
}

@media (min-width: 480px) and (max-width: 598px) {
  .fottersocialmediadiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .horizontalLine {
    width: 99%;
  }
  .copyright {
    height: 75px;
  }
  .copyrightsecdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: column;
    height: 50px;
    text-align: center;
  }
  .footersicon {
    width: 190px;
  }
  .address {
    font-size: 20px;
  }
  .footercolor {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    margin-top: 80px;
  }
  .locationicon {
    margin-top: 16px;
  }
  .location {
    display: flex;
    gap: 2px;
    width: 50%;
  }
  .locate {
    display: flex;
    width: 50%;
  }
  .footerdiv {
  }
}

@media screen and (max-width: 479px) {
  .footercolor {
    display: flex;
    justify-content: space-around;
    margin-top: 80px;
  }
  .footercolor {
    background-color: #35affd;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
  }
  .locationicon {
    color: white;
    margin-top: 15px;
  }

  .footersicon {
    gap: 20px;
  }
  .location {
    display: flex;
    gap: 2px;
    width: 50%;
  }
  .locate {
    display: flex;
    width: 50%;
  }
  .address {
    font-size: 20px;
  }
  .horizontalLine {
    width: 98%;
    height: 0px;
    color: gray;
  }
  .horizontalLinemain {
    width: 100%;
  }
  .fottersocialmediadiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .copyrightsecdiv {
    width: 100%;
    flex-direction: column;
    height: 65px;
    text-align: center;
    margin-bottom: 10px;
  }
  .copyright {
    height: 90px;
  }
}
