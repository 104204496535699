.termsandcondition{
    background-color: var(--bgcolor);
    color: white;
    text-align: center;
    font-size: 24px!important;
    font-weight: bolder;
    
}

.termscondition{
    padding: 20px;
    text-align: justify;
 }