.whole {
    display: flex;
    justify-content: space-around;
    height: 10vh;
}

.circle {
    width: 90vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.phy {
    height: 30px;
}

.logo {
    display: flex;
    align-items: center;
}

.card {
    margin-top: 30px;
    height: 10vh;
    width: 145vh;
    margin-left: 270px;

}

.sub {
    display: flex;
    align-items: center;
}

.live {
    position: relative;
    padding-left: 22em;
    font-size: 23px !important;
    bottom: 14.5em;
}

.neet {
    position: relative;
    font-size: 30px !important;
    bottom: 12em;
    left: 13em;
    color: white;
}

.listed {
    position: relative;
    bottom: 16em;
    left: 21em;
    color: white;
    font-size: 18px;
    padding: 8px;
}

html,
body {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
}