.logos {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.board {
  display: flex;
  justify-content: center;
  margin-top: 150px;
}

.cards {
  display: flex;
  padding: 5px;
  width: 700px;
  align-items: center;
}

.registerhead {
  color: var(--bgcolor);
}

.mediumlogo {
  height: 280px;
  display: flex;
  flex-direction: column;
  width: 84%;
  margin-top: 10px;
  gap: 20px;
}

.nextbtn {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

.nextbutton {
  width: 700px;
  font-size: larger;
}

@media (min-width: 610px) and (max-width: 700px) {
  .cards {
    width: 600px;
  }
}

.tamilnadu {
  font-size: 20px;
  margin-left: 10px;
}

@media (min-width: 510px) and (max-width: 609px) {
  .cards {
    width: 500px;
  }

  .tamilnadu {
    font-size: 17px;
  }

  .images {
    width: 40px;
  }
}

@media (min-width: 410px) and (max-width: 509px) {
  .cards {
    width: 400px;
  }

  .tamilnadu {
    font-size: 14px;
  }

  .images {
    width: 50px;
  }
}

@media (max-width: 409px) {
  .cards {
    width: 350px;
  }

  .tamilnadu {
    font-size: 14px;
  }

  .images {
    width: 40px;
  }

  .registerhead {
    font-size: 25px;
  }
}

@media (min-width: 350px) and (max-width: 699px) {
  .nextbutton {
    width: 83%;
  }
}
