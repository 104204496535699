.tcsnavbar {
  background-color: #35affd;
  display: flex;
  justify-content: space-between;
  padding: 3px 20px;
  height: 70px;
}
.tcslist {
  list-style: none;
  color: white;
  display: flex;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
}
.tcsfeatureicons {
  width: 17px;
  padding-right: 4px;
}
.tcsappdemoicons {
  width: 28px;
  padding-right: 4px;
}
.tcscontacticons {
  width: 18px;
  padding-right: 4px;
}
.tcsunorderlist {
  display: flex;
  gap: 50px;
}
.tcshomeicons {
  width: 25px;
  padding-right: 2px;
}
.tcsgetstart {
  padding-right: 2px;
}
.translate-container {
  display: flex;
  align-items: center;
}
.tcsdropdown {
  margin-left: -12px;
}
.MuiOutlinedInput-notchedOutline {
  border: none !important;
  /* color: #ffffff !important; */
}
.MuiSelect-outlined.MuiSelect-outlined {
  color: #ffffff !important;
  font-size: 17px;
}
.MuiSelect-icon {
  color: white !important;
}

.language-icon {
  font-size: 24px !important;
  margin-top: -2px;
}

.tcsmobile,
tcsbar {
  color: #ffff;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
@media screen and (max-width: 900px) {
  .tcsmobile,
  tcsbar {
    color: #ffff;
    font-size: 26px;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 9s ease-in;
  }
  .tcsunorderlist {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    color: #ffffff;
    top: 37px;
    right: -370px;
    width: 350px;
    height: 100vh;
    background-color: #000f19;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0);
    padding: 40px 0 0 10px;
    transition: 0.5s ease-in;
  }
  .tcsunorderlist.active {
    right: 0px;
  }
  .tcsnavbar {
    padding: 10px 20px;
  }
  .translate-container {
    margin-top: -10px;
  }
}
@media screen and (max-width: 599px) {
  .tcsunorderlist {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    color: #ffffff;
    top: 35px;
    right: -610px;
    width: 100%;
    height: 100vh;
    background-color: #000f19;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0);
    padding: 40px 0 0 10px;
    transition: 0.5s ease-in-out;
  }
}
